import { useEffect, useState } from 'react';

import { Modal } from '@playbooks/interface/modals';
import { CollectionBtnFlowForm } from 'components/collection/collection-btn-flow-form';
import { CollectionBtnFlowSelect } from 'components/collection/collection-btn-flow-select';
import { useAccount } from 'contexts';

const CollectionBtnFlow = ({ repo = {}, open, setOpen, onNext }) => {
	const [selected, setSelected] = useState([]);
	const [view, setView] = useState('select');
	const account = useAccount();

	// Hooks
	useEffect(() => {
		if (open) setSelected(repo?.collections || []);
	}, [open]);

	useEffect(() => {
		if (open) setView('select');
	}, [open]);

	// Methods
	const onClose = () => {
		setOpen(false);
	};

	const onSave = v => {
		setSelected(selected.concat(v));
		setView('select');
	};

	// Render
	return (
		<Modal open={open} onClose={onClose}>
			{view === 'select' && (
				<CollectionBtnFlowSelect
					repo={repo}
					selected={selected}
					setSelected={setSelected}
					rootApi={account.rootApi}
					setView={setView}
					onClose={onClose}
					onNext={onNext}
				/>
			)}
			{view === 'form' && (
				<CollectionBtnFlowForm
					model={{ status: 'active' }}
					setView={setView}
					rootApi={account.rootApi}
					onClose={onClose}
					onPrev={() => setView('select')}
					onNext={onSave}
				/>
			)}
		</Modal>
	);
};

export { CollectionBtnFlow };
