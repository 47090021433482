import { Fragment, useEffect, useState } from 'react';

import { AccentBtn, Btn } from '@playbooks/interface/buttons';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';
import { collectionParams } from 'api';
import { CollectionList } from 'components/collection/collection-list';
import { useAccount, useStore, useToast } from 'contexts';
import { SearchForm } from 'molecules/forms';
import { Pagination } from 'molecules/paginations';
import { AlgoliaService } from 'services';
import { log } from 'utils';

const CollectionBtnFlowSelect = ({ repo = {}, selected, setSelected, setView, rootApi, onClose, onNext }) => {
	const [collections, setCollections] = useState([]);
	const [params, setParams] = useState({ ...collectionParams, editorial: false, include: ['repos'], pageSize: '*' });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [loading, setLoading] = useState(false);
	const [taskRunning, setTaskRunning] = useState(false);
	const [query, setQuery] = useState('');
	const account = useAccount();
	const store = useStore();
	const toast = useToast();

	// Computed
	const client = new AlgoliaService({ tableName: 'collections' });

	// Hooks
	useEffect(() => {
		fetchData();
		return () => setLoading(false);
	}, [query, params]);

	useEffect(() => {
		log('collections: ', collections);
	}, [collections]);

	// Methods
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = query ? await searchDb() : await fetchDb();
			setCollections(response.data);
			setSelected(response.data.filter(v => v.repos.map(r => r.id).includes(repo.id)));
			setMeta(response.meta);
		} catch (e) {
			toast.showError(e);
		} finally {
			setLoading(false);
		}
	};

	const fetchDb = async () => {
		return await store.query({ url: `${account.rootApi}/collections`, params });
	};

	const searchDb = async () => {
		return await client.queryIndex(query, params);
	};

	const onAdd = () => {
		setView('form');
	};

	const onSave = async () => {
		try {
			setTaskRunning(true);
			const response = await store.updateRecord({
				url: `${rootApi}/collections-batch`,
				data: {
					repoId: repo.id,
					collectionIds: selected.map(v => v.id),
				},
			});
			onNext(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	};

	const onSelect = value => {
		if (selected.map(v => v.id).includes(value.id)) {
			return setSelected(selected.filter(v => v.id !== value.id));
		}
		return setSelected(selected.concat(value));
	};

	// Render
	return (
		<Fragment>
			<ModalHeader onClose={onClose}>
				<ModalTitle>Add to Collection</ModalTitle>
			</ModalHeader>

			<ModalBody border='border-b' display='flex-between' space='space-x-4'>
				<SearchForm
					id='collection_search'
					placeholder='Search my collections...'
					query={query}
					setQuery={setQuery}
					tailwind={{ inputGroup: { flex: 'grow' }, input: { spacing: 'px-0 py-2.5' } }}
				/>
				<Btn size='icon' icon='plus' onClick={onAdd} />
			</ModalBody>

			<ModalBody size='md' spacing=''>
				<CollectionList
					type='select'
					collections={collections}
					tailwind={{ feedback: { spacing: 'mx-4' }, grid: { gap: '' } }}
					selected={selected}
					loading={loading}
					onClick={onSelect}
				/>
			</ModalBody>

			<ModalBody border='' display='flex-between' space='space-x-4'>
				<Pagination meta={meta} params={params} setParams={setParams} />
			</ModalBody>

			<ModalFooter>
				<AccentBtn onClick={onClose}>Cancel</AccentBtn>
				<Btn taskRunning={taskRunning} onClick={onSave}>
					Save
				</Btn>
			</ModalFooter>
		</Fragment>
	);
};

export { CollectionBtnFlowSelect };
