import { useEffect, useState } from 'react';

import { AuthBtn } from 'components/auth/auth-btn';
import { useAccount, useStore, useToast } from 'contexts';

export const BookmarkBtn = ({ type = 'icon', model, rootApi }) => {
	const [bookmark, setBookmark] = useState<any>({});
	const [taskRunning, setTaskRunning] = useState(false);
	const account = useAccount();
	const store = useStore();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		setBookmark(model?.social?.follower || {});
	}, [model?.social]);

	// Methods
	const onClick = async () => {
		try {
			setTaskRunning(true);
			const response = await store.queryRecord({ url: `${rootApi}/follow` });
			setBookmark(response.data || {});
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning(false);
		}
	};

	const props = { bookmark, taskRunning, onClick };

	// Render
	switch (type) {
		case 'card':
			return <BookmarkCardBtn {...props} />;
		case 'hero':
			return <BookmarkHeroBtn {...props} />;
	}
};

const BookmarkCardBtn = ({ bookmark, taskRunning, onClick }) => (
	<AuthBtn
		type='border'
		size='icon'
		alt='bookmark button'
		active={bookmark.id}
		icon={bookmark.id ? 'check' : 'bookmark'}
		taskRunning={taskRunning}
		onClick={onClick}
	/>
);

const BookmarkHeroBtn = ({ bookmark, taskRunning, onClick }) => (
	<AuthBtn
		type='border'
		prevIcon={bookmark.id ? 'check' : 'bookmark'}
		alt='bookmark button'
		active={bookmark.id}
		taskRunning={taskRunning}
		onClick={onClick}>
		{bookmark.id ? 'Bookmarked' : 'Bookmark'}
	</AuthBtn>
);
