import { Fragment } from 'react';

import { FormGroup, FormInput, FormLabel, FormTextArea } from '@playbooks/interface/forms';
import { Col, Grid } from '@playbooks/interface/grid';
import { Hr } from '@playbooks/interface/html';
import { Radio } from '@playbooks/interface/radios';
import { FilestackPhotoWrapper } from 'components/wrappers/filestack-photo-wrapper';

const CollectionBasicFields = ({ collection, setCollection }) => {
	// Render
	return (
		<Fragment>
			<FormGroup>
				<FilestackPhotoWrapper
					title='Thumbnail'
					value={collection.thumbnail}
					onChange={file => setCollection({ ...collection, thumbnail: file.url })}
				/>
			</FormGroup>

			<FormGroup>
				<FormLabel>Name</FormLabel>
				<FormInput
					value={collection.name || ''}
					onChange={e => setCollection({ ...collection, name: e.target.value })}
				/>
			</FormGroup>

			<FormGroup>
				<FormLabel>Tagline</FormLabel>
				<FormInput
					value={collection.tagline || ''}
					onChange={e => setCollection({ ...collection, tagline: e.target.value })}
				/>
			</FormGroup>

			<FormGroup spacing='py-4'>
				<Hr />
			</FormGroup>

			<FormGroup>
				<FormLabel>Description</FormLabel>
				<FormTextArea
					value={collection.description || ''}
					placeholder=''
					onChange={e => setCollection({ ...collection, description: e.target.value })}
				/>
			</FormGroup>

			<FormGroup spacing='py-2'>
				<Grid>
					<Col span='6'>
						<Radio
							title='Public'
							text='Show this collection in your public profile.'
							value={collection.visibility === 'public'}
							onClick={() => setCollection({ ...collection, visibility: 'public' })}
						/>
					</Col>
					<Col span='6'>
						<Radio
							title='Private'
							text='Hide this collection from your public profile.'
							value={collection.visibility === 'private'}
							onClick={() => setCollection({ ...collection, visibility: 'private' })}
						/>
					</Col>
				</Grid>
			</FormGroup>
		</Fragment>
	);
};

export { CollectionBasicFields };
