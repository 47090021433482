import { Fragment, useEffect, useState } from 'react';

import { AuthBtn } from 'components/auth/auth-btn';
import { CollectionBtnFlow } from 'components/collection/collection-btn-flow';
import { useAccount } from 'contexts';

export const CollectionBtn = ({ type = 'card', repo }) => {
	const [collection, setCollection] = useState<any>({});
	const [open, setOpen] = useState(false);
	const account = useAccount();

	// Hooks
	useEffect(() => {
		setCollection(repo?.social?.collection || {});
	}, [repo?.social]);

	// Methods
	const onOpen = () => setOpen(true);

	const onRefresh = repo => {
		setOpen(false);
		setCollection(repo?.social?.collection || {});
	};

	const props = { repo, collection, open, setOpen, onClick: onOpen, onNext: onRefresh };

	// Render
	switch (type) {
		case 'card':
			return <CollectionCardBtn {...props} />;
		case 'hero':
			return <CollectionHeroBtn {...props} />;
	}
};

export const CollectionCardBtn = ({ repo, collection, open, setOpen, onClick, onNext, ...tailwind }) => (
	<Fragment>
		<AuthBtn
			type='border'
			size='icon'
			icon='layer-group'
			alt='collection button'
			active={collection.id}
			onClick={onClick}
			{...tailwind}
		/>
		<CollectionBtnFlow repo={repo} open={open} setOpen={setOpen} onNext={onNext} />
	</Fragment>
);

export const CollectionHeroBtn = ({ repo, collection, open, setOpen, onClick, onNext, ...tailwind }) => (
	<Fragment>
		<AuthBtn
			type='border'
			prevIcon='layer-group'
			alt='collection button'
			active={collection.id}
			onClick={onClick}
			{...tailwind}>
			Collect
		</AuthBtn>
		<CollectionBtnFlow repo={repo} open={open} setOpen={setOpen} onNext={onNext} />
	</Fragment>
);
