import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { toCurrency } from 'utils';

const RepoBadges = ({ repo, ...props }) => {
	return (
		<Fragment>
			{repo.featured && (
				<Badge {...props}>
					<FadIcon icon='badge-check' />
				</Badge>
			)}
			{repo.meta?.avgRating > 0 && (
				<Badge {...props}>
					<FadIcon icon='star' />
					<Span>{repo.meta?.avgRating}</Span>
				</Badge>
			)}
			<Badge {...props}>
				<Span>{repo.price > 0 ? toCurrency(repo.price) : 'Free'}</Span>
			</Badge>
		</Fragment>
	);
};

export { RepoBadges };
