import { Div } from '@playbooks/interface/html';
import { AccentLink } from '@playbooks/interface/links';
import { metaCardProps } from '@playbooks/interface/tailwind';

const RepoMetaCloud = ({ repo = {}, loading, ...props }) => {
	// Render
	return (
		<Div display='flex-start flex-wrap' spacing='py-1' {...props}>
			{repo.platform && (
				<AccentLink href={`/platforms/${repo.platform?.uuid}`} {...metaCardProps}>
					{repo.platform?.name}
				</AccentLink>
			)}
			{repo.language && (
				<AccentLink href={`/languages/${repo.language?.uuid}`} {...metaCardProps}>
					{repo.language?.name}
				</AccentLink>
			)}
			{repo.framework && (
				<AccentLink href={`/frameworks/${repo.framework?.uuid}`} {...metaCardProps}>
					{repo.framework?.name}
				</AccentLink>
			)}
			{repo.tool && (
				<AccentLink href={`/tools/${repo.tool?.uuid}`} {...metaCardProps}>
					{repo.tool?.name}
				</AccentLink>
			)}
			{repo.topic && (
				<AccentLink href={`/topics/${repo.topic?.uuid}`} {...metaCardProps}>
					{repo.topic?.name}
				</AccentLink>
			)}
		</Div>
	);
};

export { RepoMetaCloud };
