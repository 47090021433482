import { Fragment, useEffect, useState } from 'react';

import { AccentBtn, BorderBtn, Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { Span } from '@playbooks/interface/html';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';
import { CollectionBasicFields } from 'components/collection/collection-basic-fields';
import { useStore, useToast } from 'contexts';
import { log } from 'utils';

const CollectionBtnFlowForm = ({ model = {}, setView, rootApi, onClose, onPrev, onNext }) => {
	const [collection, setCollection] = useState<any>({});
	const [taskRunning, setTaskRunning] = useState('');
	const store = useStore();
	const toast = useToast();

	// Hooks
	useEffect(() => {
		if (open) setCollection(model);
		return () => setTaskRunning(false);
	}, [open]);

	useEffect(() => {
		log('collection: ', collection);
	}, [collection]);

	// Methods
	const onSave = async () => {
		try {
			setTaskRunning('save');
			const response = await store.saveRecord({
				url: `${rootApi}/collections`,
				data: collection,
			});
			onNext(response.data);
		} catch (e) {
			toast.showError(e);
		} finally {
			setTaskRunning('');
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Fragment>
			<Form onSubmit={onSubmit}>
				<ModalHeader onClose={onClose}>
					<ModalTitle>
						<BorderBtn size='icon' fontSize='text-lg' icon='chevron-left' onClick={onPrev} />
						<Span>Add Collection</Span>
					</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<CollectionBasicFields collection={collection} setCollection={setCollection} />
				</ModalBody>

				<ModalFooter>
					<AccentBtn onClick={onPrev}>Cancel</AccentBtn>
					<Btn type='submit' taskRunning={taskRunning}>
						Save
					</Btn>
				</ModalFooter>
			</Form>
		</Fragment>
	);
};

export { CollectionBtnFlowForm };
